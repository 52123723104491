

















































































































































































































































































































































































































































































































































































































.stu-live {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background: #070932;
}
.left-info {
    display: flex;
    flex-direction: column;
    width: 38%;
    margin: 34px 37px 0 28px;

    .name-avatar {
        display: flex;
        flex-direction: row;
        width: 247px;
        height: 48px;
        background: #262963;
        border-radius: 24px;

        .stu-avatar {
            display: inline-block;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            // border: 1px solid #DADCE7;
        }

        .nickname-fans {
            display: flex;
            flex-direction: column;
            width: 105px;
            height: 32px;
            margin: 5px 0 8px 10px;
            // border: 1px solid #DADCE7;
        }
        .stu-nickname {
            display: inline-block;
            height: 16px;
            font-size: 16px;
            font-weight: bold;
            color: #FFFFFF;
        }
        .fans-number {
            display: inline-block;
            height: 12px;
            margin-top: 8px;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
        }
        .follow {
            width: 53px;
            height: 40px;
            margin: 4px 0 4px 36px;
            text-align: center;
            background: linear-gradient(85deg, #FB2D69, #FF3E6C, #FC2A7D);
            border-radius: 20px;
            cursor: pointer;
        }
        .follow-word {
            display: inline-block;
            height: 16px;
            margin-top: 8px;
            font-size: 16px;
            font-weight: bold;
            color: #FFFFFF;
        }
        .follow-icon {
            display: inline-block;
            width: 21px;
            height: 21px;
            margin-top: 8px;
            background: url('../../assets/image/othersLive/follow.png');
        }
    }

    .stu-label {
        display: flex;
        flex-direction: row;
        // width: 247px;
        height: 30px;
        margin-top: 20px;
        .stu-label-item {
            display: flex;
            flex-direction: row;
            width: 76px;
            height: 28px;
            line-height: 30px;
            margin-right: 5px;
            background: #262963;
            border-radius: 14px;
        }
        .label-icon {
            display: inline-block;
            width: 16px;
            height: 15px;
            margin: 7px 5px 6px 5px;
            background: url('../../assets/image/othersLive/label.png');
        }
        .label-word {
            display: inline-block;
            width: 38px;
            font-size: 16px;
            color: #FFFFFF;
        }
    }

    .stu-synopsis {
        margin: 21px 0 21px 0;
        font-size: 16px;
        color: #fff;
    }
    
    .stu-likes-number {
        display: flex;
        flex-direction: row;
        height: 53px;
        .likes-icon {
            width: 53px;
            height: 53px;
            border-radius: 50%;
            background: url('../../assets/image/othersLive/dianzan.png');
            cursor: pointer;
        }
        .likes-icon-item {
            width: 53px;
            height: 53px;
            border-radius: 50%;
            background: url('../../assets/image/othersLive/dianzan-fu.png');
            cursor: pointer;
        }
        .likes-number {
            margin-left: 20px;
        }
        .likes-numb-word {
            margin-bottom: 6px;
            font-size: 16px;
            color: #fff;
        }
        .likes-n {
            font-size: 24px;
            font-weight: bold;
            color: #4B45FF
        }
        .likes-w {
            font-size: 16px;
            color: #fff;
        }
    }
}

.mid-screen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 29%;
    margin: 20px 21px 19px 0;
    background: url('../../assets/image/student/live_background.png');
    background-size: 100% 100%;
    border-radius: 20px;
    .top-box {
        display: flex;
        .left-bag-coupon {
            display: flex;
            justify-content: space-between;
            width: 125px;
            margin: 12px 0 0 10px;
            .item {
                position: relative;
                width: 60px;
                height: 60px;
                border-radius: 14px;
                background-color: rgba(0, 0, 0, 0.23);
                cursor: pointer;
                .icon {
                    width: 100%;
                    height: 100%;
                    // transform: rotate(35deg) translate(0, 9px);
                }
                .item-container {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                }
                .countdown {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 50px;
                    height: 20px;
                    font-size: 12px;
                    color: #fff;
                    border-radius: 10px;
                    background-color: rgba(0, 0, 0, 0.5);
                    opacity: 0.9;
                }
                .corner-mark {
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translate(40%, -40%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    font-size: 14px;
                    color: #d50715;
                    background: #fece0a;
                    box-shadow: 0px 4px 7px 0px rgba(94, 57, 1, 0.3);
                    border-radius: 50%;
                }
            }
        }
        .right-goods-info {
            flex: 1;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            height: 135px;
            margin: 19px 0 0 40px;
            .left-paster {
                flex: 1;
                margin-right: 15px;
                .paster-img {
                    height: 135px;
                    width: 100%;
                }
            }
            .right-info {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .price-box {
                    font-size: 16px;
                    color: #000;
                    .old-price {
                        margin-bottom: 5px;
                        .price {
                            text-decoration: line-through;
                        }
                    }
                    .new-price {
                        // font-weight: 700;
                        .price {
                            font-size: 16px;
                            color: #b41222;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
    .bottom-box {
        display: flex;
        justify-content: space-between;
        margin: 0 10px 21px 10px;
        overflow: hidden;
        .lift-bullet-action {
            display: flex;
            flex-direction: column;
            .bullet-chat {
                height: 270px;
                width: 100%;
                overflow: hidden;
                margin-bottom: 15px;
                .bullet-chat-list {
                    .item {
                        width: fit-content;
                        margin-bottom: 10px;
                        padding: 5px 10px;
                        word-break: break-all;
                        font-size: 16px;
                        color: #ff3e6c;
                        border-radius: 15px;
                        background-color: rgba(0, 0, 0, 0.3);
                        .content {
                            margin-left: 9px;
                            color: #fff;
                        }
                    }
                    .item:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .action-input {
            position: relative;
            width: 254px;
        }
        ::v-deep .input {
            height: 48px;
            input {
                height: 100%;
                padding: 0 12px;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.4);
                border: none;
                border-radius: 23px;
            }
        }
        .action-icon {
            position: absolute;
            top: 8px;
            right: 10px;
            display: inline-block;
            width: 22px;
            height: 31px;
            background: url('../../assets/image/othersLive/yuyin.png');
            cursor: pointer;
        }
        .right-goods-popup {
            position: absolute;
            right: 10px;
            bottom: 23px;
            width: 176px;
            // height: 267px;
            box-sizing: border-box;
            align-self: flex-end;
            background-color: #fff;
            border-radius: 6px;
            .img-box {
                position: relative;
                width: 170px;
                height: 170px;
                margin: 2px 2px 0 2px;
                overflow: hidden;
                .explain {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 68px;
                    height: 20px;
                    font-size: 14px;
                    color: #FFFFFF;
                    text-align: right;
                    background: url('../../assets/image/othersLive/explain.png');
                }
                .goods-img {
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                    border-radius: 6px 6px 0 0;
                }
            }
            .goods-info {
                margin: 7px 5px;
                overflow: hidden;
                .title {
                    box-sizing: border-box;
                    height: 31px;
                    font-size: 14px;
                    line-height: 18px;
                    color: #222;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2; /*几行就写数字几*/
                    text-overflow: ellipsis;
                }
                .coupon {
                    height: 42px;
                    margin-top: 7px;
                    line-height: 42px;
                    color: #fff;
                    background: url('../../assets/image/othersLive/rob.png');
                    cursor: pointer;
                    .coupon-item {
                        font-size: 12px;
                    }
                    .coupon-item-numb {
                        font-size: 18px;
                    }
                }
                .coupon span {
                    display: inline-block;
                }
                .coupon span:first-child {
                    margin-left: 16px;
                }
            }
        }
    }
    .live-video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        ::v-deep video {
            width: 100%;
            height: 100%;
            object-fit: cover !important;
        }
    }
}

.right-operation {
    display: flex;
    flex-direction: column;
    width: 29%;
    margin: 20px 20px 0 0;
    .top-box {
        display: flex;
        flex-direction: column;
        height: 369px;
        margin-bottom: 21px;
        background: #0C0E3F;
        border: 1px solid rgba(67, 154, 255, 0.15);
        box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
        border-radius: 20px;
        .title-box {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            margin-top: 27px    ;
            margin-bottom: 32px;
            padding-left: 20px;
            font-size: 18px;
            .decorate {
                width: 31px;
                height: 11px;
                margin-right: 10px;
                vertical-align: middle;
            }
            .title {
                font-size: 18px;
                font-weight: bold;
                color: #FFFFFF;
            }
        }
        .list {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            .container {
                box-sizing: border-box;
                width: 75%;
                overflow: hidden;
            }
            .rank-item {
                display: flex;
                flex-direction: row;
                margin: 0 0 15px 32px;
            }
            .rank {
                width: 27px;
                height: 29px;
                line-height: 29px;
                font-size: 16px;
                font-weight: bold;
                color: #FFFFFF;
                margin-right: 9px;
            }
            .rank-one {
                background: url('../../assets/image/othersLive/one.png') no-repeat;
            }
            .rank-two {
                background: url('../../assets/image/othersLive/two.png') no-repeat;
            }
            .rank-three {
                background: url('../../assets/image/othersLive/three.png') no-repeat;
            }
            .rank-word {
                width: 20px;
                height: 29px;
                margin-left: 8px;
            }
            .item {
                box-sizing: border-box;
                display: flex;
                width: fit-content;
                height: 30px;
                padding-right: 30px;
                font-size: 16px;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.3);
                border-radius: 15px;
                .avatar-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    margin-right: 9px;
                    border-radius: 50%;
                    overflow: hidden;
                    background-color: #fff;
                    vertical-align: middle;
                    .avatar-img {
                        width: 24px;
                        height: 24px;
                    }
                }
                .desc {
                    flex: 1;
                    overflow: hidden;
                    line-height: 30px;
                    .username {
                        color: #4b45ff;
                    }
                }
            }
        }
    }
    .mid-box {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 545px;
        margin-bottom: 47px;
        background: #0C0E3F;
        border: 1px solid rgba(67, 154, 255, 0.15);
        box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
        border-radius: 20px;
        .title-box {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            margin-top: 27px    ;
            margin-bottom: 32px;
            padding-left: 20px;
            font-size: 18px;
            .decorate {
                width: 31px;
                height: 11px;
                margin-right: 10px;
                vertical-align: middle;
            }
            .title {
                font-size: 18px;
                font-weight: bold;
                color: #FFFFFF;
            }
        }
        .list {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .container {
                box-sizing: border-box;
                width: 75%;
                overflow: hidden;
            }
            .item {
                box-sizing: border-box;
                display: flex;
                width: fit-content;
                padding-right: 10px;
                height: 30px;
                margin-bottom: 15px;
                font-size: 16px;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.3);
                border-radius: 15px;
                .avatar-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    margin-right: 9px;
                    border-radius: 50%;
                    overflow: hidden;
                    background-color: #fff;
                    vertical-align: middle;
                    .avatar-img {
                        width: 24px;
                        height: 24px;
                    }
                }
                .desc {
                    flex: 1;
                    overflow: hidden;
                    line-height: 30px;
                    .username {
                        color: #4b45ff;
                    }
                }
            }
        }
    }
    .bottom-box {
        display: flex;
        justify-content: space-between;
        height: 76px;
        .barrage-switch {
            width: 93px;
            height: 100%;
            cursor: pointer;
            .open {
                height: 36px;
                margin-bottom: 4px;
                background: url('../../assets/image/othersLive/bullet-open.png');
            }
            .shut {
                height: 36px;
                background: url('../../assets/image/othersLive/bullet-shut.png');
            }
        }
        .volume-adjusting {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            .volume-icon {
                width: 22px;
                height: 16px;
                margin-right: 5px;
                background: url('../../assets/image/othersLive/shengyin.png');
            }
            .volume-progress {
                position: relative;
                width: 150px;
                height: 16px;
                .progress {
                    margin-bottom: 6px;
                    height: 1px;
                    background: #262963;
                    border: 2px solid #262963;
                    border-radius: 2px;
                }
                .progress::-webkit-progress-value {
                    position: absolute;
                    top: 6px;
                    height: 1px;
                    background: #4B45FF;
                    border: 2px solid #4B45FF;
                    border-radius: 2px;
                }
                .progress_btn {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    cursor: pointer;
                    background: #EEEEEE;
                }
            }
        }
        .enlarge {
            width: 18px;
            height: 17px;
            background: url('../../assets/image/othersLive/lashen.png');
        }
    }
}

::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
.luckyBagDialog {
    display: flex;
    flex-direction: column;
    .avatar {
        position: absolute;
        top: -60px;
        left: 35%;
        width: 100px;
        height: 100px;
        .stu-avatar {
            width: 100px;
            height: 100px;  
            border-radius: 50%;
        }
    }
    .condition {
        width: 180px;
        height: 18px;
        margin: 46px auto;
        font-size: 18px;
        font-weight: 500;
        color: #050213;
    }
    .icon {
        width: 140px;
        height: 149px;
        margin: 36px auto;
        background: url('../../assets/image/othersLive/lucky-bag.png');
    }
    .bottom-box {
        display: flex;
        flex-direction: row;
        height: 60px;
        margin: 29px 0 0 0;
        text-align: center;
        line-height: 54px;
        border-top: 1px solid #DDDDDD;
        cursor: pointer;
        .left-btn {
            flex: 1;
            height: 54px;
            font-size: 16px;
            color: #060111;
            border-right: 1px solid #DDD;
        }
        .right-btn {
            flex: 1;
            font-size: 16px;
            color: #4B45FF;
        }
    }

    .title {
        // width: 36px;
        height: 18px;
        margin: 0 auto;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #070932;
    }
    .mid-box {
        display: flex;
        flex-direction: column;
        width: 360px;
        height: 180px;
        margin: 60px 19px 0 21px;
        .count-down {
            display: flex;
            justify-content: space-between;
            height: 80px;
            background: #F8F8F8;
            border-radius: 4px;
            margin-bottom: 20px;
            .times {
                width: 84px;
                height: 41px;
                margin: 21px 0 16px 10px;
                border-right: 1px solid #DDD;
                .times-numb {
                    width: 63px;
                    height: 19px;
                    margin-bottom: 9px;
                    font-size: 24px;
                    font-weight: bold;
                    color: #4B45FF;
                }
                .times-word {
                    width: 42px;
                    height: 14px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #5C5C6F;
                }
            }
            .people-numb {
                width: 164px;
                height: 17px;
                margin: 51px 10px 12px 0;
                font-size: 14px;
                font-weight: 400;
                color: #5C5C6F;
            }
        }
        .participate {
            display: flex;
            flex-direction: column;
            height: 80px;
            background: #F8F8F8;
            border-radius: 4px;
            .part-condition {
                height: 18px;
                margin: 18px 0 13px 10px;
                font-size: 18px;
                font-weight: 500;
                color: #5C5C6F;
            }
            .part-word {
                height: 14px;
                margin: 0 0 17px 12px;
                font-size: 14px;
                font-weight: 400;
                color: #5C5C6F;
            }
        }
    }
    .send {
        width: 360px;
        height: 40px;
        margin: 40px 20px 21px 20px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: 40px;
        background: #FF3E6C;
        border-radius: 4px;
        cursor: pointer;
    }
}
::v-deep .luckyBagDialog {
    .el-dialog {
        height: 380px;
        margin-left: 45%;
        border-radius: 20px;
    }
    .el-dialog__body {
        padding: 0;
    }
    .el-icon-close:before {
        content: ''
    }
}
.couponDialog {
    display: flex;
    flex-direction: column;
    .avatar {
        width: 60px;
        height: 60px;
        margin: -30px auto;
        border-radius: 50%;
        .stu-avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }
    }
    .coupon-tips {
        width: 146px;
        height: 19px;
        margin: 40px auto;
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
    }
    .coupon-details {
        width: 316px;
        height: 260px;
        text-align: center;
        background: url('../../assets/image/othersLive/coupon-bag-item.png');
        background-size: 100% 100%;
        .discount {
            padding-top: 15px;
            color: #FE3266;
            .discount-numb {
                font-size: 48px;
            }
            .discount-word {
                font-size: 18px;
            }
        }
        .category-name {
            display: flex;
            flex-direction: row;
            margin: 20px 0 20px 55px;
            .name {
                // width: 53px;
                height: 25px;
                background: #F9D6DF;
                border-radius: 4px;
                .name-item {
                    width: 46px;
                    height: 14px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #FE3266;
                    line-height: 25px;
                }
            }
            .category {
                // width: 152px;
                height: 18px;
                font-size: 18px;
                font-weight: bold;
                color: #1B162A;
            }
        }
        .use-limited {
            margin-left: 57px;
            text-align: left;
            font-size: 14px;
            font-weight: bold;
            color: #5C5C6F;
            .use-details {
                margin-bottom: 10px;
            }
        }
        .use-now {
            width: 284px;
            height: 40px;
            line-height: 40px;
            margin: 9% 16px 14px 20px;
            background: #FE3266;
            border-radius: 6px;
            cursor: pointer;
        }
        .use-now span{
            width: 64px;
            height: 16px;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
        }

    }
}
::v-deep .couponDialog {
    .el-dialog {
        height: 440px;
        margin: 38% 0 0 46%;
        box-shadow: 0 1px 3px rgba(0,0,0, 0);
        background: url('../../assets/image/othersLive/coupon-bag.png');
        background-size: 100% 100%;
    }
    .el-icon-close:before {
        content: ''
    }
}
